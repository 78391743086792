var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "refresh-change": _vm.refreshChange
              },
              scopedSlots: _vm._u([
                {
                  key: "menu",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: {
                            type: "text",
                            size: "small",
                            icon: "el-icon-edit"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.beforeOpen(scope.row)
                            }
                          }
                        },
                        [_vm._v("编辑\n                ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.tableFrom,
                callback: function($$v) {
                  _vm.tableFrom = $$v
                },
                expression: "tableFrom"
              }
            },
            [
              _c(
                "template",
                { slot: "menuLeft" },
                [
                  _c(
                    "el-dropdown",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-plus"
                          },
                          on: { click: _vm.addClick }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("Add")) + "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "150px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "工艺单号",
                        size: "small"
                      },
                      model: {
                        value: _vm.work,
                        callback: function($$v) {
                          _vm.work = $$v
                        },
                        expression: "work"
                      }
                    },
                    _vm._l(_vm.planCode, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.code, value: item.id }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.code) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "120px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "设备机型",
                        size: "small"
                      },
                      on: { change: _vm.changeProcess },
                      model: {
                        value: _vm.porccess,
                        callback: function($$v) {
                          _vm.porccess = $$v
                        },
                        expression: "porccess"
                      }
                    },
                    _vm._l(_vm.listComputer, function(item, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { label: item.name, value: item.id }
                        },
                        [_vm._v(_vm._s(item.name) + "\n                    ")]
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "120px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "设备编号",
                        size: "small"
                      },
                      model: {
                        value: _vm.wklnNumber,
                        callback: function($$v) {
                          _vm.wklnNumber = $$v
                        },
                        expression: "wklnNumber"
                      }
                    },
                    _vm._l(_vm.listWkln, function(item, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { label: item.code, value: item.id }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.code) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "120px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "品种",
                        size: "small"
                      },
                      model: {
                        value: _vm.varieties,
                        callback: function($$v) {
                          _vm.varieties = $$v
                        },
                        expression: "varieties"
                      }
                    },
                    _vm._l(_vm.listworkStyle, function(item, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { label: item.name, value: item.id }
                        },
                        [_vm._v(_vm._s(item.name) + "\n                    ")]
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "状态",
                        size: "small"
                      },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { label: "待执行", value: "0" } },
                        [_vm._v("待执行")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "执行中", value: "1" } },
                        [_vm._v("执行中")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "执行完毕", value: "2" } },
                        [_vm._v("执行完毕")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }